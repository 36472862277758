import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Table, Row, Col } from 'react-bootstrap';

import { BaseBackground } from '../components';
import { getElectionsApi, getCompareValue } from '../helpers/utils';
import { getMap, getCommonwealthTable, getTownData } from '../store/actions';

const MapPage = () => {
  const { media, election, town } = useParams();
  const dispatch = useDispatch();
  let params_json = {};
  let params = new URL(document.location).searchParams;
  [...params.entries()].forEach((e) => (params_json[e[0]] = e[1] === 'true'));
  const commonwealth_data = useSelector((state) => state.commonwealth_data);
  const town_data = useSelector((state) => state.town_data);
  const map = useSelector((state) => state.map);
  const pastElection = town_data.loaded
    ? town_data?.data?.aurreko_hauteskundea?.hauteskundea?.slug
    : '';
  useEffect(() => {
    dispatch(getTownData(getElectionsApi(media), election, town));
    dispatch(getCommonwealthTable(getElectionsApi(media), election, town));
    dispatch(getMap(getElectionsApi(media), election, town));
    // dispatch(getTowns(getElectionsApi(media), election));
  }, [dispatch, election, media, town]);
  useEffect(() => {
    if (
      commonwealth_data[election]?.loaded &&
      pastElection &&
      params_json.compare
    ) {
      dispatch(getMap(getElectionsApi(media), pastElection, town));
      dispatch(
        getCommonwealthTable(getElectionsApi(media), pastElection, town),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    commonwealth_data[election]?.loaded,
    pastElection,
    params_json.compare,
  ]);
  return (
    <BaseBackground>
      <h1>
        <FormattedMessage
          id="Zerrenda bozkatuenak"
          defaultMessage="Zerrenda bozkatuenak"
        />
      </h1>
      <Row>
        <Col lg={params_json.compare ? '12' : '4'}>
          <Table bordered className="map-table w-s">
            <tbody>
              {commonwealth_data[election]?.loaded &&
                commonwealth_data[election]?.data?.irabazleak &&
                Object.entries(commonwealth_data[election]?.data?.irabazleak)
                  .sort((a, b) => {
                    if (b[1].herriak.length < a[1].herriak.length) {
                      return -1;
                    }
                    return 1;
                  })
                  .map(([k, row], key) => {
                    const pastTowns =
                      commonwealth_data[pastElection]?.data?.irabazleak[k]
                        ?.herriak?.length;
                    return (
                      <tr key={key}>
                        <td
                          style={{ backgroundColor: row.irabazlea_kolorea }}
                          className="d-flex align-items-center "
                        >
                          <span className="table-pp-name text-white">
                            {row.irabazlea_akronimoa}
                          </span>
                          <img
                            className="pp-table-logo ms-auto"
                            src={row.irabazlea_logoa}
                            alt={row.irabazlea_akronimoa}
                          />
                          <span className="table-pp-towns bg-black text-center text-white p-2 w-25 m-0">
                            {row.herriak.length}
                          </span>
                          {params_json.compare &&
                            commonwealth_data[pastElection]?.loaded && (
                              <span className="table-pp-towns text-center text-white p-2 w-25 m-0">
                                {getCompareValue(row.herriak.length, pastTowns)}
                              </span>
                            )}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </Col>
        {!params_json.compare && (
          <Col lg="8">
            {map?.[election]?.loaded && (
              <div className="actual-map-container">
                <div
                  className="actual-map"
                  dangerouslySetInnerHTML={{ __html: map?.[election]?.data }}
                />
              </div>
            )}
          </Col>
        )}
      </Row>
      {params_json.compare && commonwealth_data[election]?.loaded && (
        <Row className="maps">
          {map?.[election]?.loaded && (
            <Col lg="8">
              <h2 className="text-end map-year">
                {town_data?.data?.oraingo_hauteskundea?.hauteskundea?.eguna.slice(
                  0,
                  4,
                )}
              </h2>
              <div
                className="actual-map"
                dangerouslySetInnerHTML={{ __html: map?.[election]?.data }}
              />
            </Col>
          )}
          {map?.[pastElection]?.loaded && (
            <Col lg="4">
              <h2 className="text-end map-year grey">
                {town_data?.data?.aurreko_hauteskundea?.hauteskundea?.eguna.slice(
                  0,
                  4,
                )}
              </h2>
              <div
                className="past-map"
                dangerouslySetInnerHTML={{ __html: map?.[pastElection]?.data }}
              />
            </Col>
          )}
        </Row>
      )}
    </BaseBackground>
  );
};

export default MapPage;
