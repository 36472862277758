import { api } from '../../api';
export const GET_POLITICAL_PARTY_DATA = 'GET_POLITICAL_PARTY_DATA';
export const getPoliticalPartyData = (
  api_endpoint,
  election,
  politicalParty,
) => {
  return async (dispatch) => {
    dispatch({
      type: `${GET_POLITICAL_PARTY_DATA}_PENDING`,
      election: election,
    });
    try {
      const url = `${api_endpoint}/${election}/api/alderdia/${politicalParty}/`;
      const response = await api.get(url);
      dispatch({
        type: `${GET_POLITICAL_PARTY_DATA}_SUCCESS`,
        election: election,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: `${GET_POLITICAL_PARTY_DATA}_ERROR`,
        election: election,
        payload: {
          error,
        },
      });
    }
  };
};
