import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../';
import { Form, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { messages } from '../../config';

const AdvancedConfiguration = ({
  configurableParams,
  visibility,
  setVisibility,
}) => {
  const [advancedMode, setAdvancedMode] = useState(false);
  const intl = useIntl();

  const setVisible = (param) => {
    if (param.OTHERS !== undefined) {
      delete param.OTHERS;
      setVisibility({ ...param });
    } else {
      setVisibility({ ...visibility, ...param });
    }
  };

  return (
    <>
      {!advancedMode && (
        <Button
          variant="secondary"
          className="mb-3"
          onClick={() => setAdvancedMode(true)}
        >
          <FormattedMessage
            id="Erakutsi ezarpen aurreratuak"
            defaultMessage="Erakutsi ezarpen aurreratuak"
          />{' '}
          <FontAwesomeIcon icon={faGear} size="lg" inverse />
        </Button>
      )}
      {advancedMode && (
        <>
          <Button
            active
            variant="secondary"
            className="mb-3"
            onClick={() => setAdvancedMode(false)}
          >
            <FormattedMessage
              id="Ezkutatu ezarpen aurreratuak"
              defaultMessage="Ezkutatu ezarpen aurreratuak"
            />{' '}
            <FontAwesomeIcon icon={faGear} size="lg" inverse />
          </Button>
          <Offcanvas
            // backdrop={false}
            placement="end"
            scroll={true}
            show={advancedMode}
            onHide={() => setAdvancedMode(false)}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Ezarpen aurreratuak</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(messages.advancedSettingsText),
                }}
              />
              <Card body className="mb-3">
                <Form>
                  <Row className="mb-3">
                    {configurableParams.map((p) => {
                      return (
                        <Form.Group
                          as={Col}
                          className="mb-3"
                          id="formGridCheckbox"
                        >
                          <Form.Check.Input
                            id={p.key}
                            type="checkbox"
                            checked={visibility[p.key]}
                            onChange={(ev) =>
                              setVisible({ [p.key]: ev.target.checked })
                            }
                          />
                          <Form.Check.Label htmlFor={p.key}>
                            {p.label}
                          </Form.Check.Label>
                        </Form.Group>
                      );
                    })}
                  </Row>
                </Form>
              </Card>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </>
  );
};

export default AdvancedConfiguration;
