import React, { useState, useRef } from 'react';
import {
  ListGroup,
  Form,
  Col,
  Row,
  Card,
  Overlay,
  Popover,
  Accordion,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PROVINCES } from '../../config/constants';
import { deepCompare } from '../../helpers/utils';
import AdvancedConfiguration from '../AdvancedConfiguration/AdvancedConfiguration';

import { Button } from '..';

export const CPProvincesTable = ({
  eventKey,
  election,
  media,
  fullscreen = false,
}) => {
  const configurableParams = [
    { key: 'percentage', label: 'Erakutsi portzentaia' },
    { key: 'seats', label: 'Erakutsi jarlekuak' },
    { key: 'compare', label: 'Erakutsi konparaketa' },
  ];
  const initialVisibility = {
    percentage: false,
    seats: false,
    compare: false,
  };
  const [visibility, setVisibility] = useState(initialVisibility);

  const setVisible = (param) => {
    if (param.OTHERS !== undefined) {
      delete param.OTHERS;
      setVisibility({ ...param });
    } else {
      setVisibility({ ...visibility, ...param });
    }
  };

  const [compareCheckbox, setCompareCheckbox] = useState(true);
  const [copied, setCopied] = useState(false);
  const ref = useRef(null);
  const [target, setTarget] = useState(null);
  const PDF_PORTZENTAIA_KONPARAKETA = {
    ...initialVisibility,
    compare: true,
    percentage: true,
  };
  const PDF_JARLEKU_KONPARAKETA = {
    ...initialVisibility,
    seats: true,
    compare: true,
  };
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <h3>
          {fullscreen ? (
            <FormattedMessage
              id="Probintziaren taula (Pantaila osoa)"
              defaultMessage="Probintziaren taula (Pantaila osoa)"
            />
          ) : (
            <FormattedMessage
              id="Probintziaren taula"
              defaultMessage="Probintziaren taula"
            />
          )}
        </h3>
      </Accordion.Header>
      <Accordion.Body>
        {!fullscreen && (
          <>
            <AdvancedConfiguration
              configurableParams={configurableParams}
              visibility={visibility}
              setVisibility={setVisibility}
            />
            <Row>
              <Col md={3}>
                <Card
                  bg={
                    deepCompare(visibility, PDF_PORTZENTAIA_KONPARAKETA)
                      ? 'dark'
                      : ''
                  }
                  onClick={() =>
                    setVisible({
                      ...PDF_PORTZENTAIA_KONPARAKETA,
                      OTHERS: false,
                    })
                  }
                >
                  <Card.Img
                    variant="top"
                    style={{
                      objectFit: 'cover',
                      maxHeight: '150px',
                      objectPosition: '0px 0%',
                    }}
                    src={`/assets/img/PDF_PPORTZENTAIA_KONPARAKETA.png`}
                  />
                  <Card.Body>
                    <Card.Title>{media[1].name}</Card.Title>
                    <Button
                      variant="light"
                      onClick={() =>
                        setVisible({
                          ...PDF_PORTZENTAIA_KONPARAKETA,
                          OTHERS: false,
                        })
                      }
                    >
                      Aukeratu Portzentaia konparaketa
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card
                  bg={
                    deepCompare(visibility, PDF_JARLEKU_KONPARAKETA)
                      ? 'dark'
                      : ''
                  }
                  onClick={() =>
                    setVisible({ ...PDF_JARLEKU_KONPARAKETA, OTHERS: false })
                  }
                >
                  <Card.Img
                    variant="top"
                    style={{
                      objectFit: 'cover',
                      maxHeight: '150px',
                      objectPosition: '0px 0%',
                    }}
                    src={`/assets/img/PDF_PJARLEKU_KONPARAKETA.png`}
                  />
                  <Card.Body>
                    <Card.Title>{media[1].name}</Card.Title>
                    <Button
                      variant="light"
                      onClick={() =>
                        setVisible({
                          ...PDF_JARLEKU_KONPARAKETA,
                          OTHERS: false,
                        })
                      }
                    >
                      Aukeratu Jarleku konparaketa
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
        {fullscreen && (
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-3" id="formGridCheckbox">
                <Form.Check.Input
                  id="0"
                  type="checkbox"
                  checked={compareCheckbox}
                  onChange={() => setCompareCheckbox(!compareCheckbox)}
                />{' '}
                <Form.Check.Label htmlFor="0">{'Konparaketa'}</Form.Check.Label>
              </Form.Group>
            </Row>
          </Form>
        )}
        <ListGroup variant="flush">
          {PROVINCES &&
            PROVINCES.map((province, key) => {
              let params = [];
              Object.keys(visibility).forEach((k) =>
                params.push(`${k}=${visibility[k]}`),
              );
              const link = `/tv/${election}/m/${media}/p/${province}/table${
                fullscreen ? `fullscreen?compare=${compareCheckbox}` : ''
              }${
                !fullscreen && params.length > 0 ? `?${params.join('&')}` : ''
              }`;
              return (
                <ListGroup.Item key={key}>
                  <Form>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={1}>
                        <Link
                          to={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {province}
                        </Link>
                      </Form.Label>
                      <Col sm={5}>
                        <Form.Control
                          type="text"
                          value={`${window.location.origin}${link}`}
                        />
                      </Col>
                      <Col sm={1} ref={ref}>
                        <Button
                          onClick={(ev) => {
                            navigator.clipboard.writeText(
                              `${window.location.origin}${link}`,
                            );
                            setCopied(true);
                            setTarget(ev.target);
                            setTimeout(() => {
                              setCopied(false);
                            }, 1000);
                          }}
                        >
                          <FontAwesomeIcon icon={faCopy} size="lg" inverse />
                        </Button>
                        <Overlay
                          show={copied}
                          target={target}
                          placement="top"
                          container={ref}
                          containerPadding={20}
                        >
                          <Popover id="popover-contained">
                            <Popover.Body>
                              <strong>Kopiatuta!</strong>
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      </Col>
                    </Form.Group>
                  </Form>
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};
