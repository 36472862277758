import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListGroup, Form, Accordion } from 'react-bootstrap';
import { CPLine } from '../';

export const CPPoliticalParty = ({
  eventKey,
  election,
  media,
  politicalParties,
}) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <h3>
          <FormattedMessage
            id="Alderdi politikoak"
            defaultMessage="Alderdi politikoak"
          />
        </h3>
      </Accordion.Header>
      <Accordion.Body>
        <ListGroup variant="flush">
          {politicalParties.length > 0 &&
            politicalParties.map((pp, key) => {
              const link = `/tv/${election}/m/${media}/pp/${pp.slug}`;
              return (
                <ListGroup.Item key={key}>
                  <Form>
                    <CPLine
                      link={link}
                      title={pp.akronimoa === 'PSE-EE' ? 'PSOE' : pp.akronimoa}
                    />
                  </Form>
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};
