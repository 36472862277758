import React, { useEffect, useState } from 'react';
import { Accordion, Form, Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

import {
  CPTownsTable,
  CPProvincesTable,
  CPCouncilors,
  CPMap,
  CPPoliticalParty,
  CPPieChart,
  Button,
  CPScroll,
} from '../components';
import { CP_OPTIONS, MEDIAS } from '../config/constants';
import { getElectionsApi } from '../helpers/utils';
import { getTowns, getPoliticalParties } from '../store/actions';

const Controlpanel = () => {
  const { media } = useParams();

  // const [election, setElection] = useState(ELECTIONS[0].key);

  const [localElectionVisible, setLocalElectionVisible] = useState(false);
  const [electionVisible, setElectionVisible] = useState(false);
  const [localElection, setLocalElection] = useState(
    'europako-legebiltzarrerako-hauteskundeak-2024',
  );

  // use ZZ-ZZ as election separator, for example: batzar-nagusietarako-hauteskundeak-2023ZZ-ZZnafarroako-parlamenturako-hauteskundeak-2023
  const [election, setElection] = useState(
    'europako-legebiltzarrerako-hauteskundeak-2024ZZ-ZZeuropako-legebiltzarrerako-hauteskundeak-2024',
  );
  const dispatch = useDispatch();
  const towns = useSelector((state) => state.towns);
  const politicalParties = useSelector((state) => state.politicalParties);

  useEffect(() => {
    if (media) {
      dispatch(getTowns(getElectionsApi(media), localElection));
      dispatch(
        getPoliticalParties(getElectionsApi(media), election.split('ZZ-ZZ')[0]),
      );
    }
  }, [dispatch, media, election, localElection]);

  const checkAvailableCPOption = (controlPanelType) => {
    return (
      MEDIAS[media].availableCPs.length === 0 ||
      MEDIAS[media].availableCPs.includes(controlPanelType)
    );
  };
  return (
    <Container>
      <h1>
        <FormattedMessage id="Controlpanel: " defaultMessage="Controlpanel: " />
        <FormattedMessage id={media} defaultMessage={media} />
      </h1>
      {/* <Form.Group className="mb-3">
        <Form.Select onChange={(ev) => setElection(ev.target.value)}>
          {ELECTIONS &&
            ELECTIONS.map((election) => (
              <option value={election.key}>{election.value}</option>
            ))}
        </Form.Select>
      </Form.Group> */}
      <p>
        <FormattedMessage
          id="Hemen dauzkazu telebistan erakusteko pantaila desberdinetarako estekak."
          defaultMessage="Hemen dauzkazu telebistan erakusteko pantaila desberdinetarako estekak."
        />
      </p>
      <p>
        <FormattedMessage
          id="Estekan klik eginda, leiho berri bat irekiko da, eta testu kutxan esteka aukeratuta edo eskumako botoia klikatuta kopiatu dezakezu."
          defaultMessage="Estekan klik eginda, leiho berri bat irekiko da, eta testu kutxan esteka aukeratuta edo eskumako botoia klikatuta kopiatu dezakezu."
        />
      </p>
      {(checkAvailableCPOption(CP_OPTIONS.townsFullscreenTable) ||
        checkAvailableCPOption(CP_OPTIONS.townsTable) ||
        checkAvailableCPOption(CP_OPTIONS.councilors) ||
        checkAvailableCPOption(CP_OPTIONS.map) ||
        checkAvailableCPOption(CP_OPTIONS.scroll)) && (
        <>
          <h2>
            <FormattedMessage id="Herriz herri" defaultMessage="Herriz herri" />
            <Button
              variant="link"
              onClick={() => setLocalElectionVisible(!localElectionVisible)}
            >
              <FontAwesomeIcon icon={faGear} size="lg" />
            </Button>
          </h2>
          {localElectionVisible && (
            <Form.Group className="mb-3">
              <Form.Control
                value={localElection}
                onChange={(ev) => setLocalElection(ev.target.value)}
              ></Form.Control>
            </Form.Group>
          )}
          <Accordion>
            {checkAvailableCPOption(CP_OPTIONS.townsFullscreenTable) && (
              <CPTownsTable
                eventKey="0"
                towns={towns.loaded ? towns.data : []}
                election={localElection}
                media={media}
                fullscreen={true}
              />
            )}
            {checkAvailableCPOption(CP_OPTIONS.townsTable) && (
              <CPTownsTable
                eventKey="1"
                towns={towns.loaded ? towns.data : []}
                election={localElection}
                media={media}
              />
            )}
            {checkAvailableCPOption(CP_OPTIONS.councilors) && (
              <CPCouncilors
                eventKey="2"
                towns={towns.loaded ? towns.data : []}
                election={localElection}
                media={media}
              />
            )}
            {checkAvailableCPOption(CP_OPTIONS.map) && (
              <CPMap
                eventKey="3"
                towns={towns.loaded ? towns.data : []}
                election={localElection}
                media={media}
              />
            )}
            {checkAvailableCPOption(CP_OPTIONS.scroll) && (
              <CPScroll
                eventKey="4"
                towns={towns.loaded ? towns.data : []}
                election={localElection}
                media={media}
              />
            )}
          </Accordion>
        </>
      )}
      {(checkAvailableCPOption(CP_OPTIONS.provincesTable) ||
        checkAvailableCPOption(CP_OPTIONS.politicalParty) ||
        checkAvailableCPOption(CP_OPTIONS.pieChart)) && (
        <>
          <h2 className="mt-3">
            <FormattedMessage id="Probintziak" defaultMessage="Probintziak" />
            <Button
              variant="link"
              onClick={() => setElectionVisible(!electionVisible)}
            >
              <FontAwesomeIcon icon={faGear} size="lg" />
            </Button>
          </h2>
          {electionVisible && (
            <Form.Group className="mb-3">
              <Form.Control
                value={election}
                onChange={(ev) => setElection(ev.target.value)}
              ></Form.Control>
            </Form.Group>
          )}
          <Accordion>
            {checkAvailableCPOption(CP_OPTIONS.provincesFullscreenTable) && (
              <CPProvincesTable
                eventKey="0"
                media={media}
                election={election}
                fullscreen={true}
              />
            )}
            {checkAvailableCPOption(CP_OPTIONS.provincesTable) && (
              <CPProvincesTable
                eventKey="1"
                media={media}
                election={election}
              />
            )}
            {checkAvailableCPOption(CP_OPTIONS.politicalParty) && (
              <CPPoliticalParty
                eventKey="2"
                election={election}
                media={media}
                politicalParties={politicalParties?.data}
              />
            )}
            {checkAvailableCPOption(CP_OPTIONS.pieChart) && (
              <CPPieChart eventKey="3" election={election} media={media} />
            )}
          </Accordion>
        </>
      )}
    </Container>
  );
};

export default Controlpanel;
