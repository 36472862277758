import { combineReducers } from 'redux';

import languageReducer from './language';
import getTownDataReducer from './getTownDataReducer';
import getTownsReducer from './getTownsReducer';
import getPoliticalPartyDataReducer from './getPoliticalPartyDataReducer';
import getPoliticalPartiesReducer from './getPoliticalPartiesReducer';
import getProvincesDataReducer from './getProvincesDataReducer';
import getMapReducer from './getMapReducer';
import getScrollDataReducer from './getScrollDataReducer';
import getCommonwealthReducer from './getCommonwealthReducer';

const rootReducer = combineReducers({
  language: languageReducer,
  town_data: getTownDataReducer,
  provinces_data: getProvincesDataReducer,
  towns: getTownsReducer,
  politicalParty: getPoliticalPartyDataReducer,
  politicalParties: getPoliticalPartiesReducer,
  map: getMapReducer,
  commonwealth_data: getCommonwealthReducer,
  scroll_data: getScrollDataReducer,
});

export default rootReducer;
