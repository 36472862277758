import React from 'react';
import { Row, Col } from 'react-bootstrap';
export const BaseBackground = ({ children }) => {
  return (
    <Row className="base-background g-0">
      <Col sm={3} className="first-column p-lg-5 p-3"></Col>
      <Col sm={9} className="second-column p-lg-5 p-3">
        {children}
      </Col>
    </Row>
  );
};
