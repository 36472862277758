import axios from 'axios';
const instance = axios.create({
  headers: {
    Accept: 'text/html',
    'Content-Type': 'text/html',
  },
});
export const GET_MAP = 'GET_MAP';
export const getMap = (api_endpoint, election, commonwealth) => {
  return async (dispatch) => {
    dispatch({
      type: `${GET_MAP}_PENDING`,
      election: election,
    });
    try {
      const url = `${api_endpoint}/${election}/${commonwealth}/api/mapa/`;
      const response = await instance.get(url);
      dispatch({
        type: `${GET_MAP}_SUCCESS`,
        election: election,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: `${GET_MAP}_ERROR`,
        election: election,
        payload: {
          error,
        },
      });
    }
  };
};
