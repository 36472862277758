import React from 'react';
export const Councilor = ({ pl, isMayor }) => {
  return (
    <div className={isMayor ? 'mayor' : 'councilor'}>
      {pl?.argazkia?.image && (
        <img alt="politikaria" src={pl?.argazkia?.image} />
      )}
      <div
        className="bg-color text-white"
        style={{ backgroundColor: pl.alderdia?.kolorea }}
      >
        {pl.alderdia?.akronimoa}
      </div>
      <div className="councilorName">{pl.izena}</div>
    </div>
  );
};
