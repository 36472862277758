import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTownData, getTowns } from '../store/actions';
import { getElectionsApi } from '../helpers/utils';
import { TownTable } from '../components';

const TownTablePage = () => {
  const { media, election, town } = useParams();
  const dispatch = useDispatch();
  const town_data = useSelector((state) => state.town_data);
  const towns = useSelector((state) => state.towns);
  let params_json = {};
  let params = new URL(document.location).searchParams;
  [...params.entries()].forEach((e) => (params_json[e[0]] = e[1] === 'true'));

  useEffect(() => {
    dispatch(getTownData(getElectionsApi(media), election, town));
    dispatch(getTowns(getElectionsApi(media), election));
  }, [media, election, town, dispatch]);
  return (
    town_data.loaded && (
      <TownTable
        town_data={town_data.data}
        visible={params_json}
        header={true}
        heading={
          towns.data.filter((t) => t.slug === town).length > 0 &&
          towns.data.filter((t) => t.slug === town)[0].izena
        }
        className="w-s"
      />
    )
  );
};

export default TownTablePage;
