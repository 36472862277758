import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPoliticalPartyData } from '../store/actions';
import { getElectionsApi } from '../helpers/utils';
import { PoliticalPartyTable } from '../components';
const PoliticalPartyPage = () => {
  const { media, election, politicalParty } = useParams();
  const dispatch = useDispatch();
  const elections = election.split('ZZ-ZZ');
  const placesToFilter = ['gipuzkoa', 'bizkaia', 'araba', 'nafarroa'];
  const politicalPartyData = useSelector((state) => state.politicalParty);
  const ppList1 =
    politicalPartyData?.[elections[0]]?.loaded &&
    politicalPartyData?.[elections[0]]?.data
      ? politicalPartyData?.[elections[0]]?.data.filter((ppd) =>
          placesToFilter.includes(ppd.hauteskundeatokian.tokia.slug),
        )
      : [];
  const ppList2 =
    politicalPartyData?.[elections[1]]?.loaded &&
    politicalPartyData?.[elections[1]]?.data
      ? politicalPartyData?.[elections[1]]?.data.filter((ppd) =>
          placesToFilter.includes(ppd.hauteskundeatokian.tokia.slug),
        )
      : [];
  useEffect(() => {
    if (politicalParty) {
      elections[0] &&
        dispatch(
          getPoliticalPartyData(
            getElectionsApi(media),
            elections[0],
            politicalParty,
          ),
        );
      elections[1] &&
        dispatch(
          getPoliticalPartyData(
            getElectionsApi(media),
            elections[1],
            politicalParty === 'psoe' ? 'psn' : politicalParty,
          ),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, election, media, politicalParty]);
  return (
    <div>
      {(ppList1.length > 0 || ppList2.length > 0) && (
        <PoliticalPartyTable pp_data={[...ppList1, ...ppList2]} />
      )}
    </div>
  );
};

export default PoliticalPartyPage;
