import React from 'react';
// import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { Container, Nav, Navbar as BootstrapNavbar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { MEDIAS } from '../../config/constants';
import './Navbar.scss';

const Navbar = () => {
  const medias = Object.keys(MEDIAS);
  const location = useLocation();

  return window.location.pathname.startsWith('/tv') ? (
    <></>
  ) : (
    <BootstrapNavbar expand="lg">
      <Container>
        <BootstrapNavbar.Brand as={Link} to="/">
          Tk hauteskundeak
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav
            className="me-auto"
            activeKey={location.pathname}
            variant="pills"
          >
            {medias.map((media, index) => (
              <Nav.Link
                as={Link}
                to={`cp/${media}`}
                eventKey={`/cp/${media}`}
                key={`${media}-${index}`}
              >
                <FormattedMessage id={media} defaultMessage={media} />
              </Nav.Link>
            ))}
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};
export default Navbar;
