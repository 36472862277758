import { api } from '../../api';
export const GET_PROVINCES_DATA = 'GET_PROVINCES_DATA';
export const getProvincesData = (api_endpoint, election, town) => {
  return async (dispatch) => {
    dispatch({
      type: `${GET_PROVINCES_DATA}_PENDING`,
      election: election,
    });
    try {
      const url = `${api_endpoint}/${election}/${town}/api/`;
      const response = await api.get(url);
      dispatch({
        type: `${GET_PROVINCES_DATA}_SUCCESS`,
        election: election,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: `${GET_PROVINCES_DATA}_ERROR`,
        election: election,
        payload: {
          error,
        },
      });
    }
  };
};
