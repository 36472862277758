import React, { useState } from 'react';
import { ListGroup, Form, Accordion, Row, Col, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { CPLine, Button } from '../';
import { MEDIAS, PROVINCES } from '../../config/constants';
import { deepCompare } from '../../helpers/utils';
import AdvancedConfiguration from '../AdvancedConfiguration/AdvancedConfiguration';

export const CPTownsTable = ({
  towns,
  media,
  eventKey,
  election,
  fullscreen = false,
}) => {
  const configurableParams = [
    { key: 'votes', label: 'Erakutsi bozkak' },
    { key: 'percentage', label: 'Erakutsi portzentaia' },
    { key: 'seats', label: 'Erakutsi jarlekuak' },
    { key: 'pastSeats', label: 'Erakutsi iraganekoak' },
    { key: 'compare', label: 'Erakutsi konparaketa' },
  ];
  const initialVisibility = {
    votes: false,
    percentage: false,
    seats: false,
    pastSeats: false,
    compare: false,
  };
  const [visibility, setVisibility] = useState(initialVisibility);

  const setVisible = (param) => {
    if (param.OTHERS !== undefined) {
      delete param.OTHERS;
      setVisibility({ ...param });
    } else {
      setVisibility({ ...visibility, ...param });
    }
  };

  // const PDF_7A1 = { ...initialVisibility, seats: true, pastSeats: true };
  // const PDF_7A2 = { ...initialVisibility, votes: true, seats: true };
  // const PDF_7B1 = { ...initialVisibility, seats: true, compare: true };
  const PDF_BOZKA_PORTZENTAIA = {
    ...initialVisibility,
    votes: true,
    percentage: true,
  };
  const PDF_BOZKA_KONPARAKETA = {
    ...initialVisibility,
    votes: true,
    compare: true,
  };
  const PDF_PORTZENTAIA_KONPARAKETA = {
    ...initialVisibility,
    percentage: true,
    compare: true,
  };

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <h3>
          {fullscreen ? (
            <FormattedMessage
              id="Herriz herriko taula (Pantaila osoa)"
              defaultMessage="Herriz herriko taula (Pantaila osoa)"
            />
          ) : (
            <FormattedMessage
              id="Herriz herriko taula"
              defaultMessage="Herriz herriko taula"
            />
          )}
        </h3>
      </Accordion.Header>
      <Accordion.Body>
        {!fullscreen && (
          <>
            <AdvancedConfiguration
              configurableParams={configurableParams}
              visibility={visibility}
              setVisibility={setVisibility}
            />
            <Row>
              {/* <Col md={3}>
                <Card
                  bg={deepCompare(visibility, PDF_7A1) ? 'dark' : ''}
                  onClick={() => setVisible({ ...PDF_7A1, OTHERS: false })}
                >
                  <Card.Img
                    variant="top"
                    style={{
                      objectFit: 'cover',
                      maxHeight: '150px',
                      objectPosition: '0px 0%',
                    }}
                    src={`/assets/img/PDF_7A1.png`}
                  />
                  <Card.Body>
                    <Card.Title>{media[1].name}</Card.Title>
                    <Button
                      variant="light"
                      onClick={() => setVisible({ ...PDF_7A1, OTHERS: false })}
                    >
                      Aukeratu 7A1
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card bg={deepCompare(visibility, PDF_7A2) ? 'dark' : ''}>
                  <Card.Img
                    variant="top"
                    style={{
                      objectFit: 'cover',
                      maxHeight: '150px',
                      objectPosition: '0px 0%',
                    }}
                    src={`/assets/img/PDF_7A2.png`}
                  />
                  <Card.Body>
                    <Card.Title>{media[1].name}</Card.Title>
                    <Button
                      variant="light"
                      onClick={() => setVisible({ ...PDF_7A2, OTHERS: false })}
                    >
                      Aukeratu 7A2
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card bg={deepCompare(visibility, PDF_7B1) ? 'dark' : ''}>
                  <Card.Img
                    variant="top"
                    style={{
                      objectFit: 'cover',
                      maxHeight: '150px',
                      objectPosition: '0px 0%',
                    }}
                    src={`/assets/img/PDF_7B1.png`}
                  />
                  <Card.Body>
                    <Card.Title>{media[1].name}</Card.Title>
                    <Button
                      variant="light"
                      onClick={() => setVisible({ ...PDF_7B1, OTHERS: false })}
                    >
                      Aukeratu 7B1
                    </Button>
                  </Card.Body>
                </Card>
              </Col> */}
              <Col md={3}>
                <Card
                  bg={
                    deepCompare(visibility, PDF_BOZKA_PORTZENTAIA) ? 'dark' : ''
                  }
                  onClick={() =>
                    setVisible({ ...PDF_BOZKA_PORTZENTAIA, OTHERS: false })
                  }
                >
                  <Card.Img
                    variant="top"
                    style={{
                      objectFit: 'cover',
                      maxHeight: '150px',
                      objectPosition: '0px 0%',
                    }}
                    src={`/assets/img/PDF_BOZKA_PORTZENTAIA.png`}
                  />
                  <Card.Body>
                    <Card.Title>{media[1].name}</Card.Title>
                    <Button
                      variant="light"
                      onClick={() =>
                        setVisible({ ...PDF_BOZKA_PORTZENTAIA, OTHERS: false })
                      }
                    >
                      Aukeratu Bozkak eta portzentaiak
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card
                  bg={
                    deepCompare(visibility, PDF_BOZKA_KONPARAKETA) ? 'dark' : ''
                  }
                  onClick={() =>
                    setVisible({ ...PDF_BOZKA_KONPARAKETA, OTHERS: false })
                  }
                >
                  <Card.Img
                    variant="top"
                    style={{
                      objectFit: 'cover',
                      maxHeight: '150px',
                      objectPosition: '0px 0%',
                    }}
                    src={`/assets/img/PDF_BOZKA_KONPARAKETA.png`}
                  />
                  <Card.Body>
                    <Card.Title>{media[1].name}</Card.Title>
                    <Button
                      variant="light"
                      onClick={() =>
                        setVisible({ ...PDF_BOZKA_KONPARAKETA, OTHERS: false })
                      }
                    >
                      Aukeratu Bozka konparaketa
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card
                  bg={
                    deepCompare(visibility, PDF_PORTZENTAIA_KONPARAKETA)
                      ? 'dark'
                      : ''
                  }
                  onClick={() =>
                    setVisible({
                      ...PDF_PORTZENTAIA_KONPARAKETA,
                      OTHERS: false,
                    })
                  }
                >
                  <Card.Img
                    variant="top"
                    style={{
                      objectFit: 'cover',
                      maxHeight: '150px',
                      objectPosition: '0px 0%',
                    }}
                    src={`/assets/img/PDF_PORTZENTAIA_KONPARAKETA.png`}
                  />
                  <Card.Body>
                    <Card.Title>{media[1].name}</Card.Title>
                    <Button
                      variant="light"
                      onClick={() =>
                        setVisible({
                          ...PDF_PORTZENTAIA_KONPARAKETA,
                          OTHERS: false,
                        })
                      }
                    >
                      Aukeratu Portzentaia konparaketa
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
        <ListGroup variant="flush" className="mt-3">
          {towns &&
            [
              ...towns.filter(
                (town) =>
                  ![
                    'espainia',
                    ...PROVINCES,
                    MEDIAS[media].commonwealth,
                  ].includes(town.slug),
              ),
              ...towns.filter(
                (town) => MEDIAS[media].commonwealth === town.slug,
              ),
            ].map((town, key) => {
              let params = [];
              Object.keys(visibility).forEach((k) =>
                params.push(`${k}=${visibility[k]}`),
              );

              const link = `/tv/${election}/m/${media}/t/${town.slug}/table${
                fullscreen ? 'fullscreen' : ''
              }${
                !fullscreen && params.length > 0 ? `?${params.join('&')}` : ''
              }`;
              return (
                <ListGroup.Item key={key}>
                  <Form>
                    <CPLine link={link} title={town.izena} />
                  </Form>
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};
