import { GET_MAP } from '../actions';

const initialState = {};

const getMapReducer = (state = initialState, action) => {
  const election = action.election;
  switch (action.type) {
    case `${GET_MAP}_SUCCESS`:
      return {
        ...state,
        [election]: {
          ...state[election],
          data: action.payload,
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${GET_MAP}_PENDING`:
      return {
        ...state,
        [election]: {
          ...state[election],
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${GET_MAP}_ERROR`:
      return {
        ...state,
        [election]: {
          ...state[election],
          loading: false,
          loaded: false,
          error: action.payload.error,
        },
      };
    // Default case
    default:
      return state;
  }
};

export default getMapReducer;
