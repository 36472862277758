import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTowns, getTownData } from '../store/actions';
import { getElectionsApi, getSplittedCouncilors } from '../helpers/utils';
import { BaseBackground, Councilor } from '../components';

const Councilors = () => {
  const { media, election, town } = useParams();
  const dispatch = useDispatch();
  const town_data = useSelector((state) => state.town_data);
  const towns = useSelector((state) => state.towns);
  const councilors = town_data?.data?.eserlekuak || [];
  useEffect(() => {
    dispatch(getTownData(getElectionsApi(media), election, town));
    dispatch(getTowns(getElectionsApi(media), election));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [mayor, first, second, third, fourth] = getSplittedCouncilors(
    councilors.filter((c) => c.is_selected),
  );

  return (
    <BaseBackground>
      <h1 className="mb-3">
        {towns.data.filter((t) => t.slug === town).length > 0 &&
          towns.data.filter((t) => t.slug === town)[0].izena}
      </h1>
      <div className="d-flex">
        {mayor.izena && <Councilor pl={mayor} isMayor={true} />}
        <div className="first-second-col">
          <div className="d-flex">
            {first.map((pl) => (
              <Councilor pl={pl} isMayor={false} />
            ))}
          </div>
          <div className="d-flex">
            {second.map((pl) => (
              <Councilor pl={pl} isMayor={false} />
            ))}
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="d-flex">
            {third.map((pl) => (
              <Councilor pl={pl} isMayor={false} />
            ))}
          </div>
        </div>
        <div>
          <div className="d-flex">
            {fourth.map((pl) => (
              <Councilor pl={pl} isMayor={false} />
            ))}
          </div>
        </div>
      </div>
    </BaseBackground>
  );
};

export default Councilors;
