import React from 'react';
import { Route, Routes } from 'react-router-dom';
import App from './App';
import { INDEX_COMPONENT, ROUTES } from './config';

const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route index element={<Navigate to={'/'} />}></Route> */}
      <Route path="/" element={<App />}>
        <Route index element={<INDEX_COMPONENT />} />
        {Object.values(ROUTES).map((route, key) => {
          return (
            <Route key={key} path={route.path} element={<route.Component />} />
          );
        })}
      </Route>
    </Routes>
  );
};
export default AppRoutes;
