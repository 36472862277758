import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProvincesData, getTowns } from '../store/actions';
import { getElectionsApi } from '../helpers/utils';
import { TownTable } from '../components';

const ProvinceTablePage = () => {
  const { media, election, province } = useParams();
  const dispatch = useDispatch();
  const provinces_data = useSelector((state) => state.provinces_data);
  const towns = useSelector((state) => state.towns);
  const elections = election.split('ZZ-ZZ');
  const election_slug = province === 'nafarroa' ? elections[1] : elections[0];
  let params_json = {};
  let params = new URL(document.location).searchParams;
  [...params.entries()].forEach((e) => (params_json[e[0]] = e[1] === 'true'));

  useEffect(() => {
    dispatch(getProvincesData(getElectionsApi(media), elections[0], province));
    dispatch(getProvincesData(getElectionsApi(media), elections[1], province));
    dispatch(getTowns(getElectionsApi(media), election));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, election, media, province]);
  return (
    <>
      {provinces_data?.[election_slug]?.loaded && (
        <TownTable
          town_data={provinces_data?.[election_slug]?.data}
          header={true}
          heading={
            towns.data.filter((t) => t.slug === province).length > 0 &&
            towns.data.filter((t) => t.slug === province)[0].izena
          }
          visible={params_json}
          className="w-s"
        />
      )}
    </>
  );
};

export default ProvinceTablePage;
