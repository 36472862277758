import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ListGroup, Form, Accordion } from 'react-bootstrap';
import { CPLine } from '../';
import { MEDIAS } from '../../config/constants';

export const CPScroll = ({ towns, media, eventKey, election }) => {
  const [timerValue, settimerValue] = useState(100);
  const link = `/tv/${election}/m/${media}/scroll?timer=${timerValue}`;
  const commonwealthName = towns.find(
    (t) => t.slug === MEDIAS[media].commonwealth,
  )?.izena;
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <h3>
          <FormattedMessage id="Scroll" defaultMessage="Scroll" />
        </h3>
      </Accordion.Header>
      <Accordion.Body>
        <Form.Group className="mb-3">
          <Form.Label column sm={12}>
            Zenbat segundutan pasako dira herri guztiak{' '}
            <strong>
              (10 eta 1000 arteko 10-en multiploa den zenbaki bat)
            </strong>
          </Form.Label>
          <Form.Control
            value={timerValue}
            onChange={(ev) => settimerValue(ev.target.value)}
          ></Form.Control>
        </Form.Group>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Form>
              <CPLine link={link} title={commonwealthName} />
            </Form>
          </ListGroup.Item>
          <ListGroup.Item>
            <Form>
              <CPLine
                link={`${link}&provinces=true&eae=true`}
                title={`${commonwealthName}, probintziak eta EAE`}
              />
            </Form>
          </ListGroup.Item>
        </ListGroup>
        {/* <ListGroup variant="flush">
          <ListGroup.Item>
            <Form>
              <CPLine
                link={`${link}?compare=true`}
                title={`${commonwealthName} konparaketa`}
              />
            </Form>
          </ListGroup.Item>
        </ListGroup> */}
      </Accordion.Body>
    </Accordion.Item>
  );
};
