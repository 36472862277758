import { GET_TOWNS } from '../actions';

const initialState = {
  data: [],
  loading: false,
  loaded: false,
  error: null,
};

const getTownsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_TOWNS}_SUCCESS`:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
      };
    case `${GET_TOWNS}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case `${GET_TOWNS}_ERROR`:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        loaded: false,
      };
    // Default case
    default:
      return state;
  }
};

export default getTownsReducer;
