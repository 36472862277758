import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  goiena: {
    id: 'goiena',
    defaultMessage: 'Goiena',
  },
  goierri: {
    id: 'goierri',
    defaultMessage: 'Goierri',
  },
  '28kanala': {
    id: '28kanala',
    defaultMessage: '28Kanala',
  },
  erlo: {
    id: 'erlo',
    defaultMessage: 'Erlo',
  },
  advancedSettingsText: {
    id: 'advanced_settings_text',
    defaultMessage:
      '<p>Lehenetsitako aukerez gain, zure gustuko parametrizazioa konfiguratu dezakezu.</p><p>Bukatu ondoren, leihoa itxi eta aldaketak zuzenean agertuko dira esteketan.</p>',
  },
});
