import { api } from '../../api';
export const GET_SCROLL_DATA = 'GET_SCROLL_DATA';
export const getScrollData = (api_endpoint, election) => {
  return async (dispatch) => {
    dispatch({
      type: `${GET_SCROLL_DATA}_PENDING`,
    });
    try {
      const url = `${api_endpoint}/${election}/toki-guztiak/api/`;
      const response = await api.get(url);
      dispatch({
        type: `${GET_SCROLL_DATA}_SUCCESS`,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: `${GET_SCROLL_DATA}_ERROR`,
        payload: {
          error,
        },
      });
    }
  };
};
