export const DOMAIN_PATH = 'https://eu.wikipedia.org';
export const API_PATH = `${DOMAIN_PATH}/api/rest_v1`;
export const DB_KEYS = {
  LANGUAGE: 'language',
};

export const PROVINCES = [
  'araba',
  'bizkaia',
  'gipuzkoa',
  'nafarroa',
  'EAE',
  'espainia',
];

export const ELECTIONS = [
  { key: 'udal-hauteskundeak-2019', value: 'Udal Hauteskundeak 2019' },
  { key: 'udal-hauteskundeak-2023', value: 'Udal Hauteskundeak 2023' },
];

export const CP_OPTIONS = {
  townsFullscreenTable: 'towns_fullscreen_table',
  townsTable: 'towns_table',
  councilors: 'councilors',
  map: 'map',
  scroll: 'scroll',
  provincesTable: 'provinces_table',
  provincesFullscreenTable: 'provinces_fullscreen_table',
  politicalParty: 'political_party',
  pieChart: 'pie_chart',
};
export const MEDIAS = {
  goiena: {
    name: 'Goiena',
    // elections_api: 'https://tokikom-staging.korpoweb.com/hauteskundeak',
    elections_api: 'https://goiena.eus/hauteskundeak',
    commonwealth: 'debagoiena',
    availableCPs: [], // Gehitu CP_OPTIONSeko aldagaiak bakarrik aukera horiek aktibatzeko. Hutsik uzten baduzu ([]), denak gaituko dira.
  },
  goierri: {
    name: 'Goierri',
    elections_api: 'https://gitb.eus/hauteskundeak',
    commonwealth: 'goierri',
    availableCPs: [],
  },
  '28kanala': {
    name: '28 kanala',
    elections_api: 'https://28kanala.eus/hauteskundeak',
    commonwealth: 'tolosaldea',
    availableCPs: [],
  },
  erlo: {
    name: 'Erlo telebista',
    elections_api: 'https://uztarria.eus/hauteskundeak',
    commonwealth: 'urola-kosta',
    availableCPs: [],
  },
  euskalerriairratia: {
    name: 'euskalerria irratia',
    elections_api: 'https://euskalerriairratia.eus/hauteskundeak',
    commonwealth: 'irunerria',
    availableCPs: [],
  },
  testing: {
    name: 'TESTING',
    elections_api: 'https://tokikom-staging.korpoweb.com/hauteskundeak',
    commonwealth: 'debagoiena',
    availableCPs: [],
  },
};
