import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProvincesData, getTowns } from '../store/actions';
import { getElectionsApi, fixNumber } from '../helpers/utils';
import { BaseBackground, TownTable } from '../components';

const ProvinceTableFullscreenPage = () => {
  const { media, election, province } = useParams();
  const dispatch = useDispatch();
  const provinces_data = useSelector((state) => state.provinces_data);
  const towns = useSelector((state) => state.towns);
  const elections = election.split('ZZ-ZZ');
  const election_slug = province === 'nafarroa' ? elections[1] : elections[0];
  let params = new URL(document.location).searchParams;
  useEffect(() => {
    dispatch(getProvincesData(getElectionsApi(media), elections[0], province));
    dispatch(getProvincesData(getElectionsApi(media), elections[1], province));
    dispatch(getTowns(getElectionsApi(media), election));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, election, media, province]);
  let params_json = {};
  [...params.entries()].forEach((e) => (params_json[e[0]] = e[1] === 'true'));
  return (
    <BaseBackground>
      <h1>
        {towns.data.filter((t) => t.slug === province).length > 0 &&
          towns.data.filter((t) => t.slug === province)[0].izena}
      </h1>
      {provinces_data?.[election_slug]?.data?.oraingo_hauteskundea
        ?.eskrutinioa && (
        <h2>
          {`Zenbaketa: %${fixNumber(
            provinces_data?.[election_slug]?.data?.oraingo_hauteskundea
              ?.eskrutinioa,
          )}`}
        </h2>
      )}
      {provinces_data?.[election_slug]?.loaded && (
        <TownTable
          town_data={provinces_data?.[election_slug]?.data}
          visible={{
            votes: true,
            percentage: true,
            seats: false,
            pastSeats: params_json.compare,
            compare: params_json.compare,
          }}
          className={params_json.compare ? '' : 'table-35-percent'}
        />
      )}
    </BaseBackground>
  );
};

export default ProvinceTableFullscreenPage;
