import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Button } from '../components';
import { MEDIAS } from '../config/constants';
import { Link } from 'react-router-dom';
const Home = () => {
  return (
    <Container>
      <h1>
        <FormattedMessage
          id="Tokikom hauteskundeak"
          defaultMessage="Tokikom hauteskundeak"
        />
      </h1>
      <h2>
        <FormattedMessage
          id="Hemen aurkituko dituzue hauteskundeetarako telebistan erakusteko datuak."
          defaultMessage="Hemen aurkituko dituzue hauteskundeetarako telebistan erakusteko datuak."
        />
      </h2>
      <p>
        <FormattedMessage
          id="Hedabide bakoitzerako helbide bana izango duzue. Bertan ikus ditzakezue pantaila desberdinetarako herriz herriko helbideak."
          defaultMessage="Hedabide bakoitzerako helbide bana izango duzue. Bertan ikus ditzakezue pantaila desberdinetarako herriz herriko helbideak."
        />
      </p>
      <p>
        <FormattedMessage
          id="Hona hedabideetarako helbideak:"
          defaultMessage="Hona hedabideetarako helbideak:"
        />
      </p>
      <Row>
        {Object.entries(MEDIAS).map((media, key) => {
          return (
            <Col lg="4" md="6" key={key}>
              <Card style={{ width: '18rem' }}>
                <Card.Img
                  style={{ padding: '1rem' }}
                  variant="top"
                  src={`/assets/img/${media[0]}.png`}
                />
                <Card.Body>
                  <Card.Title>{media[1].name}</Card.Title>
                  <Button as={Link} to={`cp/${media[0]}`}>
                    <strong>Joan panelera</strong>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Home;
