import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListGroup, Form, Accordion } from 'react-bootstrap';
import { CPLine } from '../';
import { PROVINCES, MEDIAS } from '../../config/constants';
export const CPCouncilors = ({ towns, media, eventKey, election }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <h3>
          <FormattedMessage
            id="Zinegotzien zerrenda"
            defaultMessage="Zinegotzien zerrenda"
          />
        </h3>
      </Accordion.Header>
      <Accordion.Body>
        <ListGroup variant="flush">
          {towns &&
            towns
              .filter(
                (town) =>
                  ![
                    'espainia',
                    'EAE',
                    ...PROVINCES,
                    MEDIAS[media].commonwealth,
                  ].includes(town.slug),
              )
              .map((town, key) => {
                const link = `/tv/${election}/m/${media}/t/${town.slug}/councilors`;
                return (
                  <ListGroup.Item key={key}>
                    <Form>
                      <CPLine link={link} title={town.izena} />
                    </Form>
                  </ListGroup.Item>
                );
              })}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};
