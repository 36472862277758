import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getScrollData, getTowns } from '../store/actions';
import { getElectionsApi, fixNumber } from '../helpers/utils';
import { PROVINCES, MEDIAS } from '../config/constants';

const findName = (slug, towns) => {
  return towns.find((t) => t.slug === slug).izena;
};

const getAbstentzioa = (errolda, boto_emaileak) => {
  return fixNumber(((1 - boto_emaileak / errolda) * 100).toFixed(1), true);
};

const isValidTimer = (seconds, max) => {
  if (seconds < 5 || max < seconds) {
    return false;
  }
  if (seconds % 5 !== 0) {
    return false;
  }
  return true;
};
const ScrollPage = () => {
  const { media, election } = useParams();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(0);
  const scroll_data = useSelector((state) => state.scroll_data);
  const towns = useSelector((state) => state.towns);
  let params = new URL(document.location).searchParams;
  const include_eae = params.get('eae') === 'true' ? ['EAE'] : [];
  const include_provinces =
    params.get('provinces') === 'true'
      ? PROVINCES.filter((p) => p !== 'EAE')
      : [];
  const excludes = ['espainia', ...PROVINCES, MEDIAS[media].commonwealth];
  const scroll_data_towns = [
    ...towns?.data
      .filter((k) => !excludes.includes(k.slug))
      .map((t) => t.slug)
      .sort(),
    ...towns?.data
      .filter((k) => include_provinces.includes(k.slug))
      .map((t) => t.slug)
      .sort(),
    ...towns?.data
      .filter((k) => include_eae.includes(k.slug))
      .map((t) => t.slug)
      .sort(),
  ];

  setTimeout(() => {
    setRefresh(refresh + 1);
    // }, 60000);
  }, 300000);

  useEffect(() => {
    dispatch(getScrollData(getElectionsApi(media), election));
    dispatch(getTowns(getElectionsApi(media), election));
  }, [media, election, dispatch]);

  useEffect(() => {
    if (refresh !== 0) {
      dispatch(getScrollData(getElectionsApi(media), election));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    towns.loaded && (
      <div className="ticker-wrap">
        <div
          className={`ticker ticker-${
            isValidTimer(params.get('timer'), 1000) ? params.get('timer') : 100
          }`}
        >
          {scroll_data_towns?.length > 0 &&
            scroll_data_towns.map((t, key) => (
              <React.Fragment key={key}>
                {scroll_data?.data?.[t] &&
                  parseInt(
                    scroll_data?.data?.[t]?.oraingo_hauteskundea?.eskrutinioa,
                  ) > 0 && (
                    <div className="ticker__item">
                      <span className="location-name">
                        <strong>{findName(t, towns.data)}</strong> (%
                        {fixNumber(
                          scroll_data?.data[t]?.oraingo_hauteskundea
                            ?.eskrutinioa,
                        )}
                        ):
                      </span>
                      &emsp;
                      {scroll_data?.data[t]?.taula
                        ?.filter((a) => a.alderdia__show_in_page)
                        .map((a) => {
                          return (
                            <>
                              <strong>{a.alderdia__akronimoa}</strong>{' '}
                              {PROVINCES.includes(findName(t, towns.data))
                                ? `%${fixNumber(a.ehunekoa.toFixed(1), true)} `
                                : `${fixNumber(a.botoak)} `}
                              (
                              {scroll_data?.data[t].oraingo_hauteskundea
                                ?.jarlekuen_kopurua ? (
                                <strong>{a.jarlekuak}</strong>
                              ) : (
                                <strong>
                                  %{fixNumber(a.ehunekoa.toFixed(1), true)}
                                </strong>
                              )}
                              )&emsp;
                            </>
                          );
                        })}
                      {scroll_data?.data[t]?.oraingo_hauteskundea
                        ?.eskrutinioa === '100.00' &&
                        scroll_data?.data[t]?.oraingo_hauteskundea?.errolda && (
                          <>
                            <strong>Abstentzioa</strong>
                            {' %'}
                            {getAbstentzioa(
                              scroll_data?.data[t]?.oraingo_hauteskundea
                                ?.errolda,
                              scroll_data?.data[t]?.oraingo_hauteskundea
                                ?.boto_emaileak,
                            )}
                            &emsp;
                          </>
                        )}
                    </div>
                  )}
              </React.Fragment>
            ))}
        </div>
      </div>
    )
  );
};

export default ScrollPage;
