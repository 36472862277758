import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTownData, getTowns } from '../store/actions';
import { getElectionsApi, fixNumber } from '../helpers/utils';
import { BaseBackground } from '../components';
import Chart from 'react-apexcharts';

const PieChartPage = () => {
  const { media, election, town } = useParams();
  const dispatch = useDispatch();
  const town_data = useSelector((state) => state.town_data);
  const towns = useSelector((state) => state.towns);
  useEffect(() => {
    dispatch(getTownData(getElectionsApi(media), election, town));
    dispatch(getTowns(getElectionsApi(media), election));
  }, [dispatch, election, media, town]);

  const stats = {
    series: town_data.data.taula
      ? town_data.data.taula
          .filter((pp) => pp.jarlekuak)
          .map((pp) => pp.jarlekuak)
      : [],
    options: {
      dataLabels: {
        enabled: true,
        formatter: (val, opts) => {
          return opts.w.config.series[opts.seriesIndex];
        },
        style: { fontSize: '3rem' },
        textAnchor: 'end',
      },
      chart: {
        width: 1800,
        type: 'donut',
      },
      colors: town_data.data.taula
        ? town_data.data.taula
            .filter((pp) => pp.jarlekuak)
            .map((pp) => pp.alderdia__kolorea)
        : [],
      labels: town_data.data.taula
        ? town_data.data.taula
            .filter((pp) => pp.jarlekuak)
            .map((pp) => pp.alderdia__akronimoa)
        : [],
      plotOptions: {
        pie: {
          donut: {
            size: '25%',

            labels: {
              show: true,
              value: {
                fontSize: '3rem',
                fontWeight: 'bolder',
              },
              total: {
                show: true,
                label: '',
              },
            },
          },
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
        },
      },
      legend: {
        markers: {
          width: 20,
          height: 20,
        },
        position: 'top',
        fontSize: '22rem',
        fontWeight: 700,
        itemMargin: {
          horizontal: 10,
          vertical: 0,
        },
      },
    },
  };
  return (
    <BaseBackground>
      <h1>
        {towns.data.filter((t) => t.slug === town).length > 0 &&
          towns.data.filter((t) => t.slug === town)[0].izena}
      </h1>
      {town_data.data?.oraingo_hauteskundea?.eskrutinioa && (
        <h2>
          {`Zenbaketa: %${fixNumber(
            town_data.data?.oraingo_hauteskundea?.eskrutinioa,
          )}`}
        </h2>
      )}
      <br />
      <div className="chart-container">
        <Chart options={stats.options} series={stats.series} type="donut" />
      </div>
    </BaseBackground>
  );
};

export default PieChartPage;
