import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListGroup, Form, Accordion } from 'react-bootstrap';
import { CPLine } from '..';
import { PROVINCES } from '../../config/constants';

export const CPPieChart = ({ eventKey, election, media }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <h3>
          <FormattedMessage
            id="Gazta erdiaren grafika"
            defaultMessage="Gazta erdiaren grafika"
          />
        </h3>
      </Accordion.Header>
      <Accordion.Body>
        <ListGroup variant="flush">
          {PROVINCES.filter((p) => p !== 'nafarroa').map((p, key) => {
            const link = `/tv/${
              election.split('ZZ-ZZ')[0]
            }/m/${media}/t/${p}/piechart`;
            return (
              <ListGroup.Item key={key}>
                <Form>
                  <CPLine link={link} title={p} />
                </Form>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};
