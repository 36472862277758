import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListGroup, Form, Accordion } from 'react-bootstrap';
import { CPLine } from '../';
import { MEDIAS } from '../../config/constants';
export const CPMap = ({ towns, media, eventKey, election }) => {
  const link = `/tv/${election}/m/${media}/t/${MEDIAS[media].commonwealth}/map`;
  const commonwealthName = towns.find(
    (t) => t.slug === MEDIAS[media].commonwealth,
  )?.izena;
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <h3>
          <FormattedMessage id="Mapa" defaultMessage="Mapa" />
        </h3>
      </Accordion.Header>
      <Accordion.Body>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Form>
              <CPLine link={link} title={commonwealthName} />
            </Form>
          </ListGroup.Item>
        </ListGroup>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Form>
              <CPLine
                link={`${link}?compare=true`}
                title={`${commonwealthName} konparaketa`}
              />
            </Form>
          </ListGroup.Item>
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};
