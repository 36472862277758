import { api } from '../../api';
export const GET_TOWNS = 'GET_TOWNS';
export const getTowns = (api_endpoint, election) => {
  return async (dispatch) => {
    dispatch({
      type: `${GET_TOWNS}_PENDING`,
    });
    try {
      const url = `${api_endpoint}/${election}/api/tokiak/`;
      const response = await api.get(url);
      dispatch({
        type: `${GET_TOWNS}_SUCCESS`,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: `${GET_TOWNS}_ERROR`,
        payload: {
          error,
        },
      });
    }
  };
};
