import { MEDIAS } from '../config/constants';
function string_to_slug(str) {
  // console.log('string_to_slug: ', str);
  return str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

const getElectionsApi = (media) => {
  const media_data = MEDIAS[media];
  return media_data.elections_api;
};

const getSplittedCouncilors = (councilors) => {
  // const first = councilors.shift();
  if (councilors.length === 0) {
    return [{}, [], [], [], []];
  }
  let half = Math.round((councilors.length - 1) / 2);
  if (half > 6) {
    half = 6;
  }
  return [
    councilors[0],
    councilors.slice(1, half + 1),
    councilors.slice(half + 1, half * 2 + 1),
    councilors.slice(half * 2 + 1, half * 2 + 1 + 8),
    councilors.slice(half * 2 + 1 + 8, half * 2 + 1 + 8 + 8),
  ];
};

const deepCompare = (arg1, arg2) => {
  if (
    Object.prototype.toString.call(arg1) ===
    Object.prototype.toString.call(arg2)
  ) {
    if (
      Object.prototype.toString.call(arg1) === '[object Object]' ||
      Object.prototype.toString.call(arg1) === '[object Array]'
    ) {
      if (Object.keys(arg1).length !== Object.keys(arg2).length) {
        return false;
      }
      return Object.keys(arg1).every(function (key) {
        return deepCompare(arg1[key], arg2[key]);
      });
    }
    return arg1 === arg2;
  }
  return false;
};

const fixNumber = (number, percentage = false) => {
  if (!number) {
    return number;
  }
  return parseFloat(number).toLocaleString('es', {
    minimumFractionDigits: percentage ? 1 : 0,
  });
};

const getCompareValue = (actual, prev) => {
  const value = prev ? actual - prev : actual;
  const int_value = parseInt(value);
  if (int_value > 0) {
    return `+${int_value}`;
  } else if (int_value === 0) {
    return '=';
  } else if (!Number.isNaN(int_value)) {
    return int_value;
  } else {
    return 0;
  }
};

export {
  string_to_slug,
  getElectionsApi,
  getSplittedCouncilors,
  deepCompare,
  fixNumber,
  getCompareValue,
};
