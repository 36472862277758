import { GET_POLITICAL_PARTIES } from '../actions';

const initialState = {
  data: {},
  loading: false,
  loaded: false,
  error: null,
};

const getPoliticalPartiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_POLITICAL_PARTIES}_SUCCESS`:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
      };
    case `${GET_POLITICAL_PARTIES}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case `${GET_POLITICAL_PARTIES}_ERROR`:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        loaded: false,
      };
    // Default case
    default:
      return state;
  }
};

export default getPoliticalPartiesReducer;
