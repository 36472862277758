import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Button } from '..';
import { Form, Col, Row, Overlay, Popover } from 'react-bootstrap';
export const CPLine = ({ link, title }) => {
  const [copied, setCopied] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  return (
    <Form.Group as={Row} className="mb-3">
      <Form.Label column sm={2}>
        <Link to={link} target="_blank" rel="noopener noreferrer">
          {title}
        </Link>
      </Form.Label>
      <Col sm={5}>
        <Form.Control type="text" value={`${window.location.origin}${link}`} />
      </Col>
      <Col sm={1} ref={ref}>
        <Button
          onClick={(ev) => {
            navigator.clipboard.writeText(`${window.location.origin}${link}`);
            setCopied(true);
            setTarget(ev.target);
            setTimeout(() => {
              setCopied(false);
            }, 1000);
          }}
        >
          <FontAwesomeIcon icon={faCopy} size="lg" inverse />
        </Button>
        <Overlay
          show={copied}
          target={target}
          placement="top"
          container={ref}
          containerPadding={20}
        >
          <Popover id="popover-contained">
            <Popover.Body>
              <strong>Kopiatuta!</strong>
            </Popover.Body>
          </Popover>
        </Overlay>
      </Col>
    </Form.Group>
  );
};
