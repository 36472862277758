import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { fixNumber } from '../../helpers/utils';
export const PoliticalPartyTable = ({ pp_data, heading = '' }) => {
  return (
    <Table bordered className="politicalparty-table w-s">
      <thead>
        {pp_data.length > 0 && (
          <tr
            className="table-pp-header text-white"
            style={{ backgroundColor: pp_data[0].alderdia.kolorea }}
          >
            <th className="partidua">
              <span className="table-pp-name">
                {pp_data[0].alderdia.akronimoa === 'PSE-EE'
                  ? 'PSOE'
                  : pp_data[0].alderdia.akronimoa}
              </span>
              {pp_data[0].alderdia?.logoa?.image ? (
                <img
                  className="pp-table-logo"
                  src={pp_data[0].alderdia?.logoa.image}
                  alt={pp_data[0].alderdia.akronimoa}
                />
              ) : (
                <>{pp_data[0]?.alderdia?.akronimoa}</>
              )}
            </th>
          </tr>
        )}
      </thead>
      <tbody>
        {pp_data &&
          pp_data.map((row) => (
            <tr>
              <td>
                <span className="pp-province-name">
                  {row.hauteskundeatokian?.tokia?.izena}
                </span>
                <Row>
                  <Col lg={6}>%{fixNumber(row.ehunekoa.toFixed(1), true)}</Col>
                  <Col
                    lg={3}
                    className="text-end"
                    style={{ color: pp_data[0].alderdia.kolorea }}
                  >
                    {row.jarlekuak}
                  </Col>
                  <Col lg={3} className=" text-end grey">
                    {row.get_jarlekuak_aurrekoan
                      ? row.get_jarlekuak_aurrekoan
                      : 0}
                  </Col>
                </Row>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
