import { api } from '../../api';
export const GET_TOWN_DATA = 'GET_TOWN_DATA';
export const getTownData = (api_endpoint, election, town) => {
  return async (dispatch) => {
    dispatch({
      type: `${GET_TOWN_DATA}_PENDING`,
      payload: { town },
    });
    try {
      const url = `${api_endpoint}/${election}/${town}/api/`;
      const response = await api.get(url);
      dispatch({
        type: `${GET_TOWN_DATA}_SUCCESS`,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: `${GET_TOWN_DATA}_ERROR`,
        payload: {
          error,
          town,
        },
      });
    }
  };
};
