import {
  Home,
  Controlpanel,
  TownTableFullscreenPage,
  TownTablePage,
  ProvinceTableFullscreenPage,
  Councilors,
  MapPage,
  PoliticalPartyPage,
  ScrollPage,
  PieChartPage,
  ProvinceTablePage,
} from '../pages';

export const INDEX_COMPONENT = Home;
const ROUTES = {
  CONTROLPANEL: {
    path: 'cp/:media',
    Component: Controlpanel,
  },
  TOWN_TABLE_FULLSCREEN: {
    path: 'tv/:election/m/:media/t/:town/tablefullscreen',
    Component: TownTableFullscreenPage,
  },
  TOWN_TABLE: {
    path: 'tv/:election/m/:media/t/:town/table',
    Component: TownTablePage,
  },
  PROVINCE_TABLE_FULLSCREEN: {
    path: 'tv/:election/m/:media/p/:province/tablefullscreen',
    Component: ProvinceTableFullscreenPage,
  },
  PROVINCE_TABLE: {
    path: 'tv/:election/m/:media/p/:province/table',
    Component: ProvinceTablePage,
  },

  TOWN_COUNCILORS: {
    path: 'tv/:election/m/:media/t/:town/councilors',
    Component: Councilors,
  },
  MAP_PAGE: {
    path: 'tv/:election/m/:media/t/:town/map',
    Component: MapPage,
  },
  POLITICAL_PARTY_PAGE: {
    path: 'tv/:election/m/:media/pp/:politicalParty',
    Component: PoliticalPartyPage,
  },
  SCROLL: {
    path: 'tv/:election/m/:media/scroll',
    Component: ScrollPage,
  },
  PIE_CHART_PAGE: {
    path: 'tv/:election/m/:media/t/:town/piechart',
    Component: PieChartPage,
  },
};

export default ROUTES;
