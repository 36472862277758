import { GET_PROVINCES_DATA } from '../actions';

const initialState = {};

const getProvincesDataReducer = (state = initialState, action) => {
  const election = action.election;
  switch (action.type) {
    case `${GET_PROVINCES_DATA}_SUCCESS`:
      return {
        ...state,
        [election]: {
          ...state[election],
          data: action.payload,
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${GET_PROVINCES_DATA}_PENDING`:
      return {
        ...state,
        [action.election]: {
          ...state[action.election],
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${GET_PROVINCES_DATA}_ERROR`:
      return {
        ...state,
        [action.election]: {
          ...state[action.election],
          loading: false,
          loaded: false,
          error: action.payload.error,
        },
      };
    // Default case
    default:
      return state;
  }
};

export default getProvincesDataReducer;
