import { GET_SCROLL_DATA } from '../actions';

const initialState = {};

const getScrollDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_SCROLL_DATA}_SUCCESS`:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
      };
    case `${GET_SCROLL_DATA}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case `${GET_SCROLL_DATA}_ERROR`:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        loaded: false,
      };
    // Default case
    default:
      return state;
  }
};

export default getScrollDataReducer;
