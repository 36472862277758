import { api } from '../../api';
export const GET_POLITICAL_PARTIES = 'GET_POLITICAL_PARTIES';
export const getPoliticalParties = (api_endpoint, election) => {
  return async (dispatch) => {
    dispatch({
      type: `${GET_POLITICAL_PARTIES}_PENDING`,
    });
    try {
      const url = `${api_endpoint}/${election}/api/alderdiak_guztiak/`;
      const response = await api.get(url);
      dispatch({
        type: `${GET_POLITICAL_PARTIES}_SUCCESS`,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: `${GET_POLITICAL_PARTIES}_ERROR`,
        payload: {
          error,
        },
      });
    }
  };
};
