import React from 'react';
import {
  fixNumber,
  // getCompareValue
} from '../../helpers/utils';
import { Table } from 'react-bootstrap';
export const TownTable = ({
  town_data,
  visible = {},
  header = false,
  heading = '',
  className = '',
}) => {
  const { votes, percentage, seats, pastSeats, compare } = visible;
  const visibleCount = Object.values(visible).filter((e) => e === true).length;
  const hasSeats = town_data.oraingo_hauteskundea?.jarlekuen_kopurua > 0;
  const headerColumns =
    compare && ((votes && percentage) || (percentage && pastSeats))
      ? visibleCount - 1
      : visibleCount;
  return (
    <Table bordered className={`town-table ${className}`}>
      <thead>
        {header && (
          <>
            <tr className="table-town-header">
              <th colSpan={visibleCount + 1}>{heading}</th>
            </tr>
            {town_data.oraingo_hauteskundea?.eskrutinioa && (
              <tr className="table-town-subheader">
                <th colSpan={visibleCount + 1}>{`Zenbaketa %${fixNumber(
                  town_data.oraingo_hauteskundea?.eskrutinioa,
                )}`}</th>
              </tr>
            )}
          </>
        )}
        {(pastSeats || compare) && (
          <tr>
            {[...Array(headerColumns).keys()].map((i) => {
              return (
                <th className="text-end">
                  {i === 1
                    ? town_data.oraingo_hauteskundea.hauteskundea.eguna?.substring(
                        0,
                        4,
                      )
                    : ''}
                </th>
              );
            })}
            <th className="grey text-end">
              {town_data.aurreko_hauteskundea.hauteskundea.eguna?.substring(
                0,
                4,
              )}
            </th>
          </tr>
        )}
      </thead>
      <tbody>
        {town_data.taula &&
          town_data.taula
            .filter((t) => t.alderdia__show_in_page)
            .map((row, key) => {
              return (
                <tr key={key}>
                  <td
                    className="text-start text-white w-1"
                    style={{ backgroundColor: row.alderdia__kolorea }}
                  >
                    {row.logoa ? (
                      <img
                        className="table-political-pary-logo"
                        src={row.logoa}
                        alt={row.alderdia__akronimoa}
                      />
                    ) : (
                      <>{row.alderdia__akronimoa}</>
                    )}
                  </td>
                  {votes && (
                    <td className="text-end votes-cell">
                      {fixNumber(row.botoak)}
                    </td>
                  )}
                  {percentage && (
                    <td className="text-end">
                      %{fixNumber(row.ehunekoa.toFixed(1), true)}
                    </td>
                  )}
                  {seats && hasSeats && (
                    <td
                      className="text-end"
                      style={{ color: row.alderdia__kolorea }}
                    >
                      {row.jarlekuak}
                    </td>
                  )}
                  {}
                  {/* {compare && hasSeats && (
                    <td className="text-end grey">
                      {getCompareValue(
                        parseInt(row.jarlekuak || 0),
                        parseInt(row.aurrekoan_eserlekuak || 0),
                      )}
                    </td>
                  )} */}
                  {compare && (
                    <>
                      {votes && (
                        <td className="text-end grey votes-cell">
                          {typeof row.aurrekoan_botoak === 'number'
                            ? fixNumber(row.aurrekoan_botoak)
                            : row.aurrekoan_botoak}
                        </td>
                      )}
                      {percentage && (
                        <td className="text-end grey">
                          {typeof row.aurrekoan_ehunekoa === 'number'
                            ? `%${fixNumber(
                                row.aurrekoan_ehunekoa.toFixed(1),
                                true,
                              )}`
                            : row.aurrekoan_ehunekoa}
                        </td>
                      )}
                    </>
                  )}
                  {compare && seats && hasSeats && (
                    <td className="text-end grey">
                      {row.aurrekoan_eserlekuak || 0}
                    </td>
                  )}
                </tr>
              );
            })}
      </tbody>
    </Table>
  );
};
