import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTownData, getTowns } from '../store/actions';
import { getElectionsApi, fixNumber } from '../helpers/utils';
import { BaseBackground, TownTable } from '../components';

const TownTableFullscreenPage = () => {
  const { media, election, town } = useParams();
  const dispatch = useDispatch();
  const town_data = useSelector((state) => state.town_data);
  const towns = useSelector((state) => state.towns);
  useEffect(() => {
    dispatch(getTownData(getElectionsApi(media), election, town));
    dispatch(getTowns(getElectionsApi(media), election));
  }, [dispatch, election, media, town]);
  const completed =
    town_data.data?.oraingo_hauteskundea?.eskrutinioa === '100.00';
  return (
    <BaseBackground>
      <h1>
        {towns.data.filter((t) => t.slug === town).length > 0 &&
          towns.data.filter((t) => t.slug === town)[0].izena}
      </h1>
      {town_data.data?.oraingo_hauteskundea?.eskrutinioa && (
        <h2>
          {`Zenbaketa: %${fixNumber(
            town_data.data?.oraingo_hauteskundea?.eskrutinioa,
          )}`}
        </h2>
      )}
      <br />
      {town_data.loaded && (
        <TownTable
          town_data={town_data.data}
          visible={{
            votes: true,
            percentage: true,
            seats: true,
            pastSeats: !completed,
            compare: true,
          }}
        />
      )}
    </BaseBackground>
  );
};

export default TownTableFullscreenPage;
